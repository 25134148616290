<template>
	<main class="dgrid-body dgrid-extra-gap stories-container">
		<h2 class="view-title">{{ $t('storie_titolo_pagina') }}</h2>
		<div class="stories-results-sort stack-horizontal">
			<p>{{numStories+' '+$t('esplora_n_risultati')}}</p>
			<div class="stack-horizontal stories-sort-btn text--body2" data-align="center" style="--flow-space-horizontal: var(--s0); margin-left: var(--s3);">
				<span>{{$t('global_testo_ordina')}}:</span>
				<base-button 
					:text="currentOrderingLocal.label"
					type="tertiary" 
					icon="arrow-move-down" 
					icon-active="arrow-move-up" 
					dropdown
					:dropdownList="orderingOptions"
					@option-selected="changeOrderHandler"
				/>
			</div>
		</div>
		<p class="text--subtitle view-subtitle">{{ $t('storie_descrizione_pagina') }}</p>
		<div class="view-body dgrid-container dgrid-minw-maxn">

			<template v-if="loading && !stories.length">
				<base-cs-story
					v-for="i in 6"
					:key="i"
					skeleton
				/>
			</template>
      <template v-else>
        <template v-for="i in stories">
          <a :href="`stories/${i.slug}`" :key="i.id" @click.prevent>
            <base-cs-story
              :item="i"
              @clicked="goToStory(i.slug)"
            /> 
          </a>  
        </template>      
      </template>
		</div>
		<div class="cover navigation-container">
			<interface-navigation v-if="showNavigation" :num-pages="numPagesStories" :page="currentPageStories" @page-changed="pageChangedHandler"/>
			<p v-else-if="!numStories">{{$t('no_results')}}</p>
		</div>

		<div class="view-bottom-margin"></div>
		
	</main>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import utils from '@js/utils.js';
import InterfaceNavigation from '@components/InterfaceNavigation.vue';
import ordering from '@mixins/ordering.js';
import API from '@js/API.js';
import _ from 'lodash';
import langMixin from '@mixins/lang.js';

export default {
	name: 'Stories',
	components: { InterfaceNavigation },
	mixins: [ordering,langMixin],
	computed: {
		...mapState('cms', [
			'stories', 
			'loading', 
			'pageSizeStories', 
			'pageStories', 
			'storiesQuery', 
			'numStories'
		]),
		...mapGetters('cms', ['numPagesStories', 'currentPageStories']),
		currentOrderingLocal() {
			return this.orderingOptions.find(el => el.slug == this.currentOrderingSlug);
		},
		orderingOptions() {
      return this.getOrderingOptionsBySlug("ordering", this.ordering.filter(f => f.slug != 'relevance').map(o => o.slug)) //that is: take all the ordering options
    },
    showNavigation() {
    	console.log(this.stories.length, this.pageSizeStories)
      return this.numStories > this.pageSizeStories;
    }
	},
	watch: {
		stories(n, o) {
			console.log('stories', _.cloneDeep(n))
		}
	},
	methods: {
		...mapActions('cms', ['getList', 'getListWithArgs', 'parseQueryObjectStories', 'setNumStories']),
		goToStory(slug) {
			this.$router.push({name: 'story', params: { slug }})
		},
		parseMediaUrl(url) {
			return utils.parseMediaUrl(url);
		},
		changeOrderHandler(option) {
			// this.getPageOnlyFlag = true;
			// console.log("order changed", option)
			this.setOrderingOptionBySlug(option.slug);
			let storiesQuery = _.cloneDeep(this.storiesQuery)
			storiesQuery.sorting = option.query
			storiesQuery.page = 1
			this.parseQueryObjectStories(storiesQuery)
			this.performStoriesQuery();
		},
		pageChangedHandler(page) {
			console.log('page', page)
			let storiesQuery = _.cloneDeep(this.storiesQuery)
			storiesQuery.page = page
			this.parseQueryObjectStories(storiesQuery)
			this.performStoriesQuery();
		},
		async getNumResults() {
			let res = await API.backend[`get_stories`]({
				page_size: 1000000,
				page: 1
			})
			res.json = res.json.filter(el => el.status == 'published');
			this.setNumStories(res.json.length)
			// console.log('numStories', numStories)
		},
		performStoriesQuery() {
			this.getListWithArgs({
				listName: 'stories',
				args: this.storiesQuery
			})
		},
		langCb() {
			this.performStoriesQuery();
		}
	},
	created: async function() {
		this.currentOrderingSlug = 'dateDecreasing';
		// this.getList('stories');
		this.getNumResults()
		this.performStoriesQuery();
		// console.log('res', res)
	}
}
</script>

<style lang="scss">

	@import '@css/variables.scss'; 

	#stories {

		.dgrid-minw-maxn {
			--dgrid-min-width: 20rem;
			--dgrid-maxn: 3;
		}
	} 

	.stories-results-sort {
		justify-content: space-between;
		align-items: center;
	}

	.stories-container .navigation-container {
		margin-top: var(--s2);
	}

	@media (max-width: $breakpoint-sm) {
		.stories-results-sort {
			flex-wrap: wrap;
			justify-content: flex-start;
			padding-top: var(--s0);
		}

		.stories-sort-btn {
			margin-left: 0 !important;
		}
	}

</style>